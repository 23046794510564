import React from 'react';
import i18next from './i18n';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>{i18next.t('test')}</p>
        <p>{i18next.t('errors:404')}</p>
      </header>
    </div>
  );
}

export default App;
