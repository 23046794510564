import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import fr_app from './translations/fr/app.json';
import fr_errors from './translations/fr/errors.json';

export const defaultNS = "app";
export const resources = {
    fr: {
        app: fr_app,
        errors: fr_errors,
    }
} as const;

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: false,
    debug: true,
    lng: 'fr-FR',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
    defaultNS,
  });


export default i18n;


